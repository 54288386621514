<template>
  <div class="liuji_chuli">
    <div class="luru">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="学科">
            <el-select v-model="discipline" placeholder="请选择对应学科">
                <el-option label="JAVA" value="Java全端开发"></el-option>
                <el-option label="HTML5" value="HTML5大前端就业班V12"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="学员姓名">
          <el-input v-model="form.stu_name" placeholder="请输入学员姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            v-model="form.tel"
            placeholder="请输入学员手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="转出班级">
          <el-select v-model="form.oldClass" placeholder="请选择原班级">
                <el-option
                    :label="item"
                    v-for="item in classNames"
                    :key="'old'+item"
                    :value="item"
                ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原课程详情">
            <el-col :span="11">
                <el-select v-model="form.jieduan_old" placeholder="请选择原课程阶段" @change="changeAction1">
                      <el-option
                            :label="item.jieduanming"
                            v-for="(item, index) in phases"
                            :key="'old'+index"
                            :value="item.jieduanming"
                      ></el-option>
                </el-select>
            </el-col>
            <el-col class="line" :span="2">
                <span style="display:block;text-align:center;margin-left:-13px;">--</span>
            </el-col>
            <el-col :span="11">
                <el-select v-model="form.kecheng_old" placeholder="请选择原课程名">
                   <el-option
                        :label="item.kechengming"
                        v-for="(item, index) in oldNames"
                        :key="'oldC'+index"
                        :value="item.kechengming"
                    ></el-option>
                </el-select>
            </el-col>
        </el-form-item>
        <el-form-item label="转入班级">
          <el-select v-model="form.newClass" placeholder="请选择新班级">
                <el-option
                    :label="item"
                    v-for="item in classNames"
                    :key="'new'+item"
                    :value="item"
                ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="新课程详情">
            <el-col :span="11">
                <el-select v-model="form.jieduan_new" placeholder="请选择新课程阶段" @change="changeAction2">
                     <el-option
                            :label="item.jieduanming"
                            v-for="(item, index) in phases"
                            :key="'new'+index"
                            :value="item.jieduanming"
                      ></el-option>
                </el-select>
            </el-col>
            <el-col class="line" :span="2">
               <span style="display:block;text-align:center;margin-left:-13px;">--</span>
            </el-col>
            <el-col :span="11">
                <el-select v-model="form.kecheng_new" placeholder="请选择新课程名">
                     <el-option
                        :label="item.kechengming"
                        v-for="(item, index) in newNames"
                        :key="'newC'+index"
                        :value="item.kechengming"
                    ></el-option>
                </el-select>
            </el-col>
        </el-form-item>
        <el-form-item label="处理时间">
            <el-date-picker
                v-model="form.date"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy/MM/dd">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="转班类型">
            <el-select v-model="form.kind" placeholder="请选择对应留级类型">
                <el-option label="留级" value="留级"></el-option>
                <el-option label="休学" value="休学"></el-option>
                <el-option label="转学科" value="转学科"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确定处理</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "LiuJi",
  data() {
    return {
      form: {
        oldClass: "",
        stu_id: "",
        stu_name:'',
        newClass: "",
        tel:'',
        jieduan_old:'',
        kecheng_old:'',
        jieduan_new:'',
        kecheng_new:'',
        date:'',
        kind:''
      },
      phases:[],
      courses:[],
      kechengs: [],
      discipline: "HTML5大前端就业班V12",
      oldNames: [],
      newNames:[],
      classNames:[]
    };
  },
  async created(){
    let res = await this.$datas.getCourses;
    this.courses = res.data;
    this.classNames = this.$route.query.classNames;
    this.filterData();
    //添加班级
    for(let i in this.classNames){
        this.$datas.meta = {className:this.classNames[i]};
        await this.$datas.addLiuJiClass;
    } 
  },
  watch: {
    discipline: {
      async handler(newV) {
        this.discipline = newV;
        this.filterData();
      },
      immediate: false,
    },
  },
  methods: {
    closeAction() {
      this.$emit("close");
    },
     filterData() {
      let jieduan = [];
      for (let i in this.courses) {
        let item = this.courses[i];
        if (item.kechengtixi == this.discipline) {
          var orExist = false;
          for (let j in jieduan) {
            if (jieduan[j].jieduanming == item.jieduanming) {
              orExist = true;
              break;
            }
          }
          if (!orExist) {
            jieduan.push({
              jdbianhao: item.jdbianhao,
              jieduanming: item.jieduanming,
            });
          }
        }
      }
      this.phases = jieduan;
    },
    changeAction1(kind) {
      let names = [];
      for (let i in this.courses) {
        let item = this.courses[i];
        if (item.jieduanming == kind && this.discipline == item.kechengtixi) {
          names.push({
            kcbianhao: item.kcbianhao,
            kechengming: item.kechengming,
          });
        }
      }
      this.oldNames = names;
    },
    changeAction2(kind) {
      let names = [];
      for (let i in this.courses) {
        let item = this.courses[i];
        if (item.jieduanming == kind && this.discipline == item.kechengtixi) {
          names.push({
            kcbianhao: item.kcbianhao,
            kechengming: item.kechengming,
          });
        }
      }
      this.newNames = names;
    },
    istel(tel) {
        var rtn = false;
        //移动号段
        var regtel = /^1[0-9]{10}$/;
        if (regtel.test(tel)) {
            rtn = true;
        }
        return rtn;
    },
    async onSubmit(){
        if(!this.istel(this.form.tel)){
            this.$message('电话号码错误');
            return 0;
        }
        this.$datas.meta = {className:this.form.newClass};
        let res = await this.$datas.getStus;
        let stus = res.data.msg.data;
        let result = false;
        for(let i in stus){
            if(stus[i].phone == this.form.tel){
                this.form.stu_id = stus[i].number;
                result = true;
                break;
            }
        }
        if(result == false){
            this.$message({
              type:"error",
              message:'该生未处理，请先去OA中处理留级！',
              duration:6000
            });
            return 0; 
        }
        //完成数据存储 
        this.$datas.meta = {...this.form};
        let res1 = await this.$datas.addLiuJiInfo;
        if(res1.status == 200){
            this.$message({
              type:"error",
              message:"留级信息已处理！",
              duration:6000
            });
        }
    }
  },
};
</script>
<style lang="less" scoped>
.liuji_chuli {
  .luru {
    width: 100%;
    height: 400px;
    margin-top: 5px;
    background-color:#fff;
    padding:50px 30px;
    box-sizing: border-box;
  }
}
</style>
